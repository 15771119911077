import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-12de41d0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-con flex flex-col"
};
import TabBar from "@/components/TabBar/index.vue";
export default {
  __name: 'layout',
  setup(__props) {
    return (_ctx, _cache) => {
      var _ctx$$route$meta, _ctx$$route$meta2;
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: _normalizeClass([(_ctx$$route$meta = _ctx.$route.meta) !== null && _ctx$$route$meta !== void 0 && _ctx$$route$meta.isHideTab ? '!h-full' : '', "cont-box h-full"])
      }, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.name
        })) : _createCommentVNode("", true)], 1024)), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.name
        })) : _createCommentVNode("", true)]),
        _: 1
      })], 2), !((_ctx$$route$meta2 = _ctx.$route.meta) !== null && _ctx$$route$meta2 !== void 0 && _ctx$$route$meta2.isHideTab) ? (_openBlock(), _createBlock(TabBar, {
        key: 0
      })) : _createCommentVNode("", true)]);
    };
  }
};