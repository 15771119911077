import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-89f0b8f8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "bar z-999"
};
const _hoisted_2 = {
  class: "tabbar-text"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  key: 0,
  class: "dot"
};
const _hoisted_5 = {
  key: 1,
  class: "dot2"
};
import HomerImg from "@/assets/v1/declare/home.png";
import HomeeImg from "@/assets/v1/declare/home2.png";
import messageeImg from "@/assets/v1/declare/information2.png";
import messagerImg from "@/assets/v1/declare/information.png";
import myeImg from "@/assets/v1/declare/my2.png";
import myrImg from "@/assets/v1/declare/my.png";
import { announceStore } from "@/store/announce";

import { ref, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
export default {
  __name: 'index',
  props: {
    //   isTeacher: {
    //     type: Boolean,
    //     default: true,
    //   },
  },
  setup(__props) {
    const active = ref("schoolEvaluation");
    const AnnounceStore = announceStore();
    const router = useRouter();
    const route = useRoute();
    const isTeacher = ref(false);
    const Props = __props;
    const tabList = ref([{
      name: "homeindex",
      to: "/index",
      desc: "首页",
      icon: HomerImg,
      activeIcon: HomeeImg
      // icon: "home-o",
    }, {
      name: "MessageList",
      to: "/MessageList/index",
      desc: "消息",
      icon: messagerImg,
      activeIcon: messageeImg
    }, {
      name: "mine",
      to: "/mine",
      desc: "我的",
      icon: myrImg,
      activeIcon: myeImg
    }]);
    const onChange = val => {
      console.log("onChange", val);
      localStorage.removeItem('aPageScrollPosition');
    };

    // 监听路由变化，
    watch(() => route.name, val => {
      const teacherTabListName = ["schoolPerformance", "aiReport", "myReview"];
      isTeacher.value = !teacherTabListName.includes(val);
    }, {
      immediate: true
    });
    onMounted(() => {
      // 判断选中
      const {
        name
      } = route;
      active.value = name;
    });
    return (_ctx, _cache) => {
      const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
      const _component_van_tabbar = _resolveComponent("van-tabbar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_tabbar, {
        modelValue: _unref(active),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(active) ? active.value = $event : null),
        onChange: onChange
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabList), item => {
          return _openBlock(), _createBlock(_component_van_tabbar_item, {
            class: "van_tabbar_item",
            key: item.name,
            name: item.name,
            to: item.to,
            replace: ""
          }, {
            icon: _withCtx(props => [_createElementVNode("img", {
              src: props.active ? item.icon : item.activeIcon
            }, null, 8, _hoisted_3), item.desc == '消息' && _unref(AnnounceStore).unread > 0 && _unref(AnnounceStore).unread < 99 ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(AnnounceStore).unread), 1)) : _createCommentVNode("", true), item.desc == '消息' && _unref(AnnounceStore).unread > 99 ? (_openBlock(), _createElementBlock("div", _hoisted_5, "99+")) : _createCommentVNode("", true)]),
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, _toDisplayString(item.desc), 1)]),
            _: 2
          }, 1032, ["name", "to"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};