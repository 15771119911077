import { showToast, showLoadingToast } from "vant";
export const announceStore = defineStore("announce", () => {
  // 发布页数据
  const announceData = ref({});
  // 未读消息数量
  const unread = ref(0);
  // 文件回显
  const fileValue = ref([]);
  // 是否需要刷新
  const refresh = ref(false);
  const refresh2 = ref(false);
  // 设置发布页数据
  const setAnnounce = item => {
    announceData.value = item;
    console.log('🎉-----announceData.value-----', announceData.value);
  };
  const setFileValue = item => {
    fileValue.value = [];
    fileValue.value = item;
    console.log('🎉-----fileValue.value-----', fileValue.value);
  };
  const setRefresh = item => {
    refresh.value = item;
    console.log('🎉-----refresh.value-----', refresh.value);
  };
  const setRefresh2 = item => {
    refresh2.value = item;
    console.log('🎉-----refresh2.value-----', refresh.value);
  };
  //清空文件回显和发布页数据
  const deleteFileValue = item => {
    fileValue.value = [];
    announceData.value = {};
    console.log('🎉-----fileValue.value-----', fileValue.value);
    console.log('🎉-----announceData.value-----', announceData.value);
  };
  // 设置未读消息数量
  const setUnread = item => {
    unread.value = item;
    console.log('🎉-----unread.value-----', unread.value);
  };
  return {
    announceData,
    setAnnounce,
    fileValue,
    setFileValue,
    deleteFileValue,
    setRefresh,
    setRefresh2,
    setUnread,
    refresh,
    refresh2,
    unread
  };
}, {
  persist: {
    key: "announce-key"
  }
});