import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "main-con",
  class: "h-full"
};
import Cookies from "js-cookie";
import { useRouter } from "vue-router";

import { onMounted } from 'vue';
export default {
  __name: 'App',
  setup(__props) {
    const router = useRouter();
    onMounted(() => {
      const newadd = Cookies.get('authorized-token');
      if (newadd) {
        router.push("/index");
      }
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view)]);
    };
  }
};