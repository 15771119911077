import { createRouter, createWebHashHistory } from "vue-router";
import Layout from "@/layout/layout.vue";
const routes = [
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/login/login.vue"),
  },
  {
    path: "/index",
    component: Layout,
    redirect: "/index",
    children: [
      {
        name: "homeindex",
        path: "/index",
        component: () =>
          import(/* webpackChunkName: "index" */ "@/views/home/index copy"),
        meta: {
          title: "首页",
          keepAlive: true,
        },
      },
      {
        name: "map",
        path: "/map",
        component: () =>
          import(/* webpackChunkName: "index" */ "@/views/Map/index"),
      },
      // {
      //   name: "index",
      //   path: "/index",
      //   component: () =>
      //     import(/* webpackChunkName: "index" */ "@/views/index"),
      //   meta: {
      //     title: "首页",
      //     keepAlive: true,
      //   },
      // },
      {
        name: "MessageList",
        path: "/MessageList/index",
        component: Layout,
        component: () => import("@/views/MessageList/index"),
        meta: {
          title: "消息",
        },
      },
      // 名师-评课
      {
        name: "Publishlesson",
        path: "/Publishlesson",
        component: () => import("@/views/TeachersEvaluating/Publishlesson"),
        meta: {
          title: "发布课例",
          isHideTab: true,
        },
      },
      {
        name: "mine",
        path: "/mine",
        component: Layout,
        component: () => import("@/views/mine"),
        meta: {
          title: "我的",
        },
      },
      // 我的个人信息修改
      {
        name: "Userinfoupdate",
        path: "/Userinfoupdate",
        component: () => import("@/views/mine/Userinfoupdate"),
        meta: {
          title: "个人信息",
          isHideTab: true,
        },
      },
      // 教学业绩
      {
        name: "Minereperformance",
        path: "/Minereperformance",
        component: () => import("@/views/mine/Minereperformance"),
        meta: {
          title: "教学业绩",
          isHideTab: true,
        },
      },
      // 教学经历
      {
        name: "Minereexperience",
        path: "/Minereexperience",
        component: () => import("@/views/mine/Minereexperience"),
        meta: {
          title: "教学业绩",
          isHideTab: true,
        },
      },
      // 我的预约
      {
        name: "Minereservation",
        path: "/Minereservation",
        component: () => import("@/views/mine/Minereservation"),
        meta: {
          title: "我的预约",
          isHideTab: true,
          keepAlive: true,
        },
      },
      // 我的预约-详情
      {
        name: "Appoindetails",
        path: "/Appoindetails",
        component: () => import("@/views/mine/Appoindetails"),
        meta: {
          title: "我的预约详情",
          isHideTab: true,
        },
      },
      // 答疑回复
      {
        name: "Minereply",
        path: "/Minereply",
        component: () => import("@/views/mine/Minereply"),
        meta: {
          title: "答疑回复",
          isHideTab: true,
          keepAlive: true,
        },
      },
      //  线下预约详情-名师-线下答疑
      {
        name: "Offlinedetails",
        path: "/Offlinedetails",
        component: () => import("@/views/mine/Offlinedetails"),
        meta: {
          title: "线下预约回复",
          isHideTab: true,
        },
      },
      {
        name: "PointsRanking",
        path: "/PointsRanking",
        component: () => import("@/views/mine/PointsRanking"),
        meta: {
          title: "积分排名",
          isHideTab: true,
        },
      },
      {
        name: "ChangePassword",
        path: "/ChangePassword",
        component: () => import("@/views/mine/ChangePassword"),
        meta: {
          title: "修改密码",
          isHideTab: true,
        },
      },
      // 教师端-收藏
      {
        name: "MineCollection",
        path: "/MineCollection",
        component: () => import("@/views/mine/MineCollection"),
        meta: {
          title: "我的收藏",
          isHideTab: true,
          keepAlive: true,
        },
      },
      //预约名师-在职
      {
        name: "teacherlist",
        path: "/reservation/teacherlist",
        component: Layout,
        component: () => import("@/views/reservation/teacherlist copy"),
        meta: {
          title: "预约名师",
          isHideTab: true,
          keepAlive: true,
        },
      },
      //预约名师-全部
      {
        name: "allteacherlist",
        path: "/reservation/allteacherlist",
        component: Layout,
        component: () => import("@/views/reservation/allteacherlist"),
        meta: {
          title: "预约名师",
          isHideTab: true,
          keepAlive: true,
        },
      },
      //名师-详情
      {
        name: "detailpage",
        path: "/reservation/detailpage",
        component: Layout,
        component: () => import("@/views/reservation/detailpage"),
        meta: {
          title: "预约名师",
          isHideTab: true,
          keepAlive: true,
        },
      },
      //名师-详情2
      {
        name: "detailpage2",
        path: "/reservation/detailpage2",
        component: Layout,
        component: () => import("@/views/reservation/detailpage2"),
        meta: {
          title: "预约名师",
          isHideTab: true,
          // keepAlive: true,
        },
      },
      //我的答疑详情
      {
        name: "details",
        path: "/answering/details",
        component: Layout,
        component: () => import("@/views/answering/details copy1.vue"),
        meta: {
          title: "预约名师",
          isHideTab: true,
        },
      },
      {
        name: "Example",
        path: "/Example/IssueMentoring",
        component: Layout,
        component: () => import("@/views/Example/IssueMentoring"),
        meta: {
          title: "名师答疑-发布问题",
          isHideTab: true,
          keepAlive: true,
        },
      },
      {
        name: "TeacherIssue",
        path: "/TeacherIssue/index",
        component: Layout,
        component: () => import("@/views/TeacherIssue/index"),
        meta: {
          title: "名师-发布",
          isHideTab: true,
        },
      },
      // {
      //   name: "Screen",
      //   path: "/Screen",
      //   component: Layout,
      //   component: () => import("@/views/Screen"),
      //   meta: {
      //     title: "筛选",
      //   },
      // },
      // {
      //   name: "DYscore",
      //   path: "/DYscore",
      //   component: Layout,
      //   component: () => import("@/views/DYscore"),
      //   meta: {
      //     title: "名师答疑-评分",
      //   },
      // },
      {
        name: "InformList",
        path: "/InformList/index",
        component: Layout,
        component: () => import("@/views/InformList/index"),
        meta: {
          title: "通知列表",
          isHideTab: true,
          keepAlive: true,
        },
      },
      {
        name: "ShiftRotation",
        path: "/ShiftRotation/index",
        component: Layout,
        component: () => import("@/views/ShiftRotation/index"),
        meta: {
          title: "排班轮值",
          isHideTab: true,
        },
      },
      {
        name: "Declare",
        path: "/Declare/index",
        component: Layout,
        component: () => import("@/views/Declare/index"),
        meta: {
          title: "个人申报服务",
          isHideTab: true,
        },
      },
      {
        name: "ApplyFor",
        path: "/ApplyFor/index",
        component: Layout,
        component: () => import("@/views/ApplyFor/index"),
        meta: {
          title: "申请",
          isHideTab: true,
        },
      },
      {
        name: "Search",
        path: "/Search/index",
        component: Layout,
        component: () => import("@/views/Search/index"),
        meta: {
          title: "搜索",
          isHideTab: true,
          keepAlive: true,
        },
      },
      // 个人信息
      {
        name: "lnformation",
        path: "/login/lnformation",
        component: Layout,
        component: () => import("@/views/login/lnformation"),
        meta: {
          title: "个人信息",
          isHideTab: true,
        },
      },
      {
        name: "OfflineReservation",
        path: "/OfflineReservation/index",
        component: Layout,
        component: () => import("@/views/OfflineReservation/index"),
        meta: {
          title: "线下预约",
          isHideTab: true,
          // keepAlive: true,
        },
      },
      {
        name: "OfflineReservations",
        path: "/OfflineReservation/detailpage",
        component: Layout,
        component: () => import("@/views/OfflineReservation/detailpage"),
        meta: {
          title: "线下预约详情",
          isHideTab: true,
          keepAlive: true,
        },
      },
      {
        name: "bookingPlace",
        path: "/OfflineReservation/bookingPlace",
        component: Layout,
        component: () => import("@/views/OfflineReservation/bookingPlace"),
        meta: {
          title: "预约地点",
          isHideTab: true,
          keepAlive: true,
        },
      },
      {
        name: "map",
        path: "/OfflineReservation/map",
        component: Layout,
        component: () => import("@/views/OfflineReservation/map"),
        meta: {
          title: "地图",
          isHideTab: true,
          // keepAlive: true,
        },
      },
      {
        name: "particulars",
        path: "/MessageList/particulars",
        component: Layout,
        component: () => import("@/views/MessageList/particulars"),
        meta: {
          title: "系统消息",
          isHideTab: true,
          keepAlive: true,
        },
      },
      //教师端我的答疑
      {
        name: "minereQA",
        path: "/mine/MinereQA",
        component: Layout,
        component: () => import("@/views/mine/MinereQA"),
        meta: {
          title: "我的答疑",
          isHideTab: true,
          isBack: false, // 判断当前页面是从C页面返回还是从其他页面进入，默认从A页面进入
          keepAlive: true,
        },
      },
    ],
  },
  //  无路由跳转至登录页
  // {
  //   path: '/:pathMatch(.*)',
  //   name: 'login',
  //   component: () => import('@/views/login/login.vue')
  // },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  if (from.path == "/answering/details" ) {
    to.meta.isBack = true;
  } else {
    to.meta.isBack = false;
  }
  next();
});

export { router };
